<template>
  <div class="container">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ $t("courseSessions.create_course_sessions") }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <br />
    <div>
      <h1>
        {{ $t("courseSessions.create_course_sessions") }}
      </h1>
      <hr />
    </div>
    <div>
      <h2>{{ $t("subTitle.baseInformation") }}</h2>
      <el-form
        ref="courseSession"
        :model="courseSession"
        @submit.prevent.native="submit"
        :validate-on-rule-change="false"
        label-position="right"
        label-width="150px"
      >
        <el-form-item
          :label="$t('courseSessions.course')"
          :rules="[commonRules.input]"
          prop="course_id"
        >
          <el-select
            v-model="courseSession.course_id"
            :placeholder="$t('courseSessions.course')"
            @change="loadDefaultCourseSetting(courseSession.course_id)"
            required
          >
            <el-option
              v-for="course in courses"
              :key="course.id"
              :label="course.title.tw"
              :value="course.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('courseSessions.title') + ' (EN)'"
          :rules="[commonRules.input]"
          :prop="'title.en'"
        >
          <el-input v-model="courseSession.title.en" required></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('courseSessions.title') + ' (TW)'"
          :rules="[commonRules.input]"
          :prop="'title.tw'"
        >
          <el-input v-model="courseSession.title.tw"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('courseSessions.title') + ' (CN)'"
          :rules="[commonRules.input]"
          :prop="'title.cn'"
        >
          <el-input v-model="courseSession.title.cn"></el-input>
        </el-form-item>
        <el-form-item :label="$t('courseSessions.short_name')">
          <el-input v-model="courseSession.short_name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('courseSessions.city')">
          <el-input v-model="courseSession.city"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('courseSessions.fee')"
          :rules="[commonRules.input]"
          prop="fee"
        >
          <el-row>
            <el-col :span="10">
              <InputMoney
                :money="courseSession.fee"
                :currency="courseSession.fee_currency"
                @changeMoney="
                  fee => {
                    courseSession.fee = fee;
                  }
                "
                @changeCurrency="
                  currency => {
                    courseSession.fee_currency = currency;
                  }
                "
              />
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item required :label="$t('courseSessions.need_pay_first')">
          <el-switch
            v-model="needPayFirst"
            :active-text="$t('courseSessions.pay_yes')"
            :inactive-text="$t('courseSessions.pay_no')"
          ></el-switch>
        </el-form-item>
        <el-form-item :label="$t('courseSessions.is_online')">
          <el-switch
            v-model="courseSession.is_online"
            :active-text="$t('courseSessions.online')"
            :inactive-text="$t('courseSessions.in_class')"
          >
          </el-switch>
        </el-form-item>
        <el-form-item :label="$t('courseSessions.start_date')">
          <el-date-picker
            v-model="courseSession.started_on"
            value-format="yyyy-MM-dd"
            type="date"
            :placeholder="$t('courseSessions.date')"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('courseSessions.end_date')">
          <el-date-picker
            v-model="courseSession.ended_on"
            value-format="yyyy-MM-dd"
            type="date"
            :placeholder="$t('courseSessions.date')"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('courseSessions.time_from')">
          <el-time-select
            v-model="courseSession.time_from"
            :picker-options="{
              start: '00:00',
              step: '00:15',
              end: '23:45'
            }"
            :placeholder="$t('courseSessions.time')"
          >
          </el-time-select>
        </el-form-item>
        <el-form-item :label="$t('courseSessions.time_to')">
          <el-time-select
            v-model="courseSession.time_to"
            :picker-options="{
              start: '00:00',
              step: '00:15',
              end: '23:45'
            }"
            :placeholder="$t('courseSessions.time')"
          >
          </el-time-select>
        </el-form-item>
        <el-form-item :label="$t('courseSessions.tests_templates')">
          <el-select
            v-if="templates !== undefined"
            style="width:100%"
            v-model="template"
            placeholder="(You can choose the tests from an existing section or type in your own.)"
            @change="setTests"
          >
            <el-option-group
              v-if="templates.course_sessions.length > 0"
              :label="$t('courseSessions.sessions_templates')"
            >
              <el-option
                v-for="(testPaper, index) in templates.course_sessions"
                :key="index + 1"
                :label="testPaper.course_session_title"
                :value="testPaper.course_session_assign_tests"
              >
              </el-option>
            </el-option-group>
            <el-option-group
              v-if="templates.session_classes.length > 0"
              :label="$t('courseSessions.classes_templates')"
            >
              <el-option
                v-for="(testPaper, index) in templates.session_classes"
                :key="index + 1"
                :label="testPaper.title"
                :value="testPaper.session_class_assign_tests"
              >
              </el-option>
            </el-option-group>
          </el-select>
          <div
            v-for="(test, index) in courseSession.course_session_assign_tests"
            :key="index"
          >
            <hr />
            <h5>
              Test{{ index }}
              <span class="pl-20">
                <el-date-picker
                  v-model="test.exam_date"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="選擇考試日期"
                >
                </el-date-picker>
              </span>
              <el-select
                filterable
                style="width:50%"
                v-model="test.exam_id"
                placeholder="選擇考卷"
              >
                <el-option
                  v-for="(item, index) in sessionsTests"
                  :key="index"
                  :label="item.full_name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <el-button
                type="text"
                class="text-danger"
                icon="el-icon-delete"
                size="mini"
                @click="removeTest(index)"
              ></el-button>
            </h5>
          </div>
          <hr />
          <el-button type="success" @click="addTest">Add Test</el-button>
        </el-form-item>

        <el-form-item>
          <template slot="label">
            <div style="text-align: left">
              {{ $t("courseSessions.tags") }}
            </div>
          </template>
          <span v-for="item in enrollmentSessionTags" :key="item.id">
            <input
              type="checkbox"
              :value="item.id"
              v-model="checkedEnrollmentSessionTags"
            />
            <span class="checkbox-label"> {{ item.name }} </span> <br />
          </span>
        </el-form-item>

        <el-form-item :label="$t('courseSessions.active')" prop="is_visible">
          <el-switch
            v-model="isVisible"
            active-text="Yes"
            inactive-text="No"
          ></el-switch>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit">{{
            $t("courseSessions.save")
          }}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { InputMoney } from "@/components/input";
import courseApi from "@/apis/course";
import formMixin from "@/mixins/form";
import enrollmentApi from "@/apis/enrollment";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  metaInfo() {
    return {
      title: `${this.$t(
        "courseSessions.create_course_sessions"
      )} - Ivy-Way Academy`
    };
  },

  components: {
    InputMoney,
    Breadcrumb
  },

  mixins: [formMixin],

  props: [],
  data() {
    return {
      sessionId: 0,
      courses: [],
      course: {},
      testPapers: [],
      enrollmentSessionTags: [],
      checkedEnrollmentSessionTags: [],
      sessionsTests: [],
      template: "",
      templates: {},
      courseSession: {
        title: {},
        short_name: "",
        is_online: 0,
        is_visible: 1,
        need_pay_first: 0,
        is_active: 1,
        fee: 0,
        fee_currency: "TWD",
        course_id: "",
        entry_test_paper_id: null,
        type: 1,
        course_session_assign_tests: []
      }
    };
  },
  computed: {
    // element switch 似乎只認 true/false, 不能用0/1，所以進行轉換
    isOnline: {
      get() {
        return this.courseSession.is_online == 1 ? true : false;
      },
      set(value) {
        this.courseSession.is_online = value == true ? 1 : 0;
      }
    },
    needPayFirst: {
      get() {
        return this.courseSession.need_pay_first ? true : false;
      },
      set(value) {
        this.courseSession.need_pay_first = value ? 1 : 0;
      }
    },
    isVisible: {
      get() {
        return this.courseSession.is_visible == 1 ? true : false;
      },
      set(value) {
        this.courseSession.is_visible = value == true ? 1 : 0;
      }
    },
    courseId: {
      get() {
        let courseId = "";
        if (this.$route.query.course_id) {
          courseId = parseInt(this.$route.query.course_id);
        }
        return courseId;
      }
    }
  },
  watch: {},

  async mounted() {
    let coursesRes = await courseApi.fetchCourses({ group_courses_only: 1 });
    this.getAssignTest();
    this.getTemplates();
    this.courses = coursesRes.courses;
    if (this.courseId > 0) {
      this.courseSession.course_id = this.courseId;
      this.loadDefaultCourseSetting(this.courseId);
    }
    let testPapersRes = await enrollmentApi.fetchTestPapers();
    this.testPapers = testPapersRes.test_papers;
    let enrollmentSessionTagsRes = await courseApi.fetchEnrollmentSessionTags();
    this.enrollmentSessionTags =
      enrollmentSessionTagsRes.enrollment_session_tags;
  },

  methods: {
    async getTemplates() {
      let res = await courseApi.getTemplates();
      this.templates = res;
    },
    setTests(val) {
      if (val.length > 0) {
        let arr = [];
        val.forEach(element => {
          let test = {
            exam_id: element.exam_id
          };
          arr.push(test);
        });
        this.courseSession.course_session_assign_tests = arr;
      } else {
        this.courseSession.course_session_assign_tests = [];
      }
    },
    addTest() {
      this.courseSession.course_session_assign_tests.push({
        exam_id: ""
      });
    },
    removeTest(index) {
      this.courseSession.course_session_assign_tests.splice(index, 1);
    },
    async getAssignTest() {
      const tests = await courseApi.getExams();
      this.sessionsTests = tests.exams;
    },
    async loadDefaultCourseSetting(course_id) {
      let courseRes = await courseApi.fetchCourse(course_id);
      this.course = courseRes.course;
      this.courseSession.title = this.course.title;
      this.courseSession.fee = Number(this.course.fee);
      this.needPayFirst = this.course.need_pay_first;
      this.courseSession.need_pay_first = this.course.need_pay_first || 0;
      this.courseSession.entry_test_paper_id = this.course.entry_test_paper_id;
    },

    async onSubmit() {
      const valid = await this.validateForm(this.$refs["courseSession"]);
      if (!valid) {
        this.$message.error(this.$t("message.form_validation_error"));
        return;
      }

      this.courseSession.tag_ids = this.checkedEnrollmentSessionTags;

      courseApi.createSession(this.courseSession).then(response => {
        this.$message({
          showClose: true,
          message: this.$t("message.create_success"),
          type: "success"
        });
        this.$router
          .push({
            name: "courseSessionsList"
          })
          .catch(error => {
            this.$message({
              showClose: true,
              message: this.$t("message.something_went_wrong"),
              type: "error"
            });
          });
      });
    }
  }
};
</script>

<style scoped>
::v-deep .el-form-item__label {
  line-height: 24px;
}
</style>
